@import "@/styles/variables.scss";

.listingAddress {
  font-size: $font-size-xs;

  &_divider {
    color: $color-gray;
  }

  &_place {
    color: $color-gray-dark;
  }
}
