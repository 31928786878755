@import "@/styles/variables";

.listingsPagination {
  margin-top: $spacing-7;

  &_list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-1;
  }

  &_item,
  &_itemEllipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: $border-radius-base;
    border: 1px solid #fff;
    cursor: pointer;
    line-height: 0;
    transition:
            background-color 0.2s ease-in-out,
            background-color 0.2s ease-in-out,
            color 0.2s ease-in-out;

    &:hover {
      border-color: $color-black;
    }

    &__isActive {
      color: #fff;
      background-color: $color-black;
      border-color: $color-black;
    }

    &[aria-disabled] {
      cursor: not-allowed;
    }

    &__isDisabled {
      color: $color-gray-dark;

      &:hover {
        border-color: #fff;
      }
    }
  }

  &_hidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
