@import "@/styles/variables";
@import "@/styles/mixins";

.userListingsPage {
  max-width: 1050px;
  margin: $spacing-4 auto;

  &_userInfo {
    display: flex;
    flex-direction: column;
    padding: $spacing-5;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }

  &_userLink {
    line-height: 0;
  }

  &_listingsCount {
    display: flex;
    margin-top: $spacing-4;
    align-items: center;
    font-size: $font-size-xxl;
    gap: $spacing-4;
  }

  &_listingsNumber {
    padding: $spacing-2 $spacing-3;
    border-radius: $border-radius-base;
    background-color: $color-black;
    font-size: $font-size-l;
    color: #fff;
  }

  &_listingsGrid {
    margin-top: $spacing-5;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: $spacing-4;
    padding: $spacing-5 0;
    border-radius: $border-radius-base;
    box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
  }
}

@include for-screen-from-360 {
  .userListingsPage {
    &_listingsGrid {
      padding: $spacing-5;
    }
  }
}

@include for-screen-from-480 {
  .userListingsPage {
    margin: $spacing-7 auto;
    padding: 0 $spacing-4;

    &_listingsGrid {
      margin-top: $spacing-7;
    }

    &_userInfo {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &_listingsCount {
      margin-top: 0;
    }
  }
}

