@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.userAccountPhotos {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-5;
  padding-left: $spacing-5;
  padding-right: $spacing-5;
  --slide-size: 226px;
  --slide-spacing: 8px;

  &_dropZone {
    position: relative;

    &[data-drop-target] {
      .userAccountPhotos_allowDrop {
        display: block;
      }
    }
  }

  &_allowDrop,
  &_notAllowDrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &_allowDrop {
    background: rgba($color-green-main, 0.2);
  }

  &_default {
    width: var(--slide-size);
    height: var(--slide-size);
    min-width: var(--slide-size);
    min-height: var(--slide-size);
    border-radius: $border-radius-base;
    border: 1px solid $color-grey4;
    background-color: $color-gray-light;
  }

  &_defaultImage {
    opacity: 0.4;
  }

  &_info {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacing-4;
    padding: $spacing-3 $spacing-2;
    z-index: 1;
    font-weight: 500;
  }

  &_infoText {
    font-size: $font-size-s;
    color: $color-grey3;
    display: block;
    font-weight: 500;
  }

  &_button {
    border-radius: $border-radius-base;
    padding: 8px 12px;
    border: $border-width-base solid $color-black;
    font-family: inherit;
    font-size: $font-size-base;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    color: $font-color-dark;
    font-weight: 500;
    transition:
      color $transition-time $transition-variant,
      background-color $transition-time $transition-variant;

    &:hover {
      color: $font-color-light;
      background-color: $color-black;
    }
  }

  &_photoCarousel {
    position: relative;
  }

  &_viewport {
    overflow: hidden;
    width: var(--slide-size);
    border-radius: $border-radius-base;
  }

  &_slideContainer {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }

  &_slide {
    position: relative;
    flex: 0 0 100%;
    min-width: 0;
    width: var(--slide-size);
    height: var(--slide-size);
    padding-left: var(--slide-spacing);
    padding-right: var(--slide-spacing);
  }

  &_image {
    display: block;
    object-fit: cover;
  }

  &_avatar,
  &_loader {
    position: relative;
    width: var(--slide-size);
    height: var(--slide-size);
    min-width: var(--slide-size);
    min-height: var(--slide-size);
    border-radius: $border-radius-base;
  }

  &_loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_photosActions {
    display: flex;
    flex-direction: column;

    row-gap: 8px;
  }

  &_photoAction {
    display: flex;
    min-width: 234px;
    justify-content: center;
    align-items: center;
    gap: $spacing-4;
    border: 1px solid $color-black;
    background-color: $color-black;
    color: #fff;
    padding: $spacing-3 $spacing-5;
    cursor: pointer;
    font-size: $font-size-base;
    border-radius: $border-radius-base;

    &:disabled {
        background-color: $color-gray-dark;
        border: 1px solid $color-gray-dark;
        //color: $color-gray-dark;
        cursor: not-allowed;
    }
  }

  &_photoDelete {
    background-color: $color-red;
    border: 1px solid $color-red;
  }

  &_photoPrimary {
    background-color: $color-green-main;
    border: 1px solid $color-green-main;
  }

  &_photoActionIcon {
    font-size: 24px;
    line-height: 0;
  }

  &_photoActionText {
    line-height: $font-size-base;
  }
}

@include for-screen-from-480 {
  .userAccountPhotos {
    flex-direction: row;
    align-items: flex-start;
    gap: 68px;
    padding-left: $spacing-6;
    padding-right: $spacing-6;
    --slide-size: 150px;

    &_photoAction {
      min-width: 150px;
    }
  }
}

@include for-screen-from-768 {
  .userAccountPhotos {
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: $spacing-5;
    --slide-size: 240px;

    &_photosActions {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 4%;
      row-gap: 8px;
    }

    &_photoAction {
      min-width: 100%;
    }
  }
}

@include for-screen-from-1024 {
  .userAccountPhotos {
    --slide-size: 300px;

    &_photoAction {
      min-width: 46%;
    }
  }
}
