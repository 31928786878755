@import "@/styles/variables.scss";

.sitemaps {
  &_placesLevel {
    display: flex;
    gap: $spacing-4;
    margin-top: $spacing-6;
  }

  &_placeLevelItem {
    cursor: pointer;
    padding: $spacing-3 $spacing-4;
    border: 1px solid transparent;
    border-radius: $border-radius-base;

    &:hover {
      border-color: $color-black;
    }

    &__selected {
      border-color: $color-black;
      background-color: $color-black;
      color: #fff;
      cursor: default;
    }
  }

  &_title {
    margin-top: $spacing-7;
    margin-bottom: $spacing-4;
    color: $color-grey3;
    font-size: $font-size-xxxxl;
    font-weight: 300;
  }


  &_subtitle {
    margin-top: $spacing-6;
    color: $color-grey3;
    font-size: $font-size-xxxl;
    font-weight: 300;
  }

  &_places {
    margin-top: $spacing-3;
    padding: 0;
    column-width: 218px;
    list-style: none;
  }

  &_placeItem {
    display: block;
    padding: $spacing-4 $spacing-3;
    color: $color-green-main;

    &:hover {
      text-decoration: underline;
    }
  }
}
