@import "@/styles/variables";
@import "@/styles/mixins";

.listingsSeo {
  margin-top: $spacing-7;
  padding: $spacing-6;
  border-radius: $border-radius-base;
  background-color: $color-gray-light;

  &__isMapView {
    display: none;
  }

  &_title {
    font-size: $font-size-xxl;
    font-weight: 500;
    margin-bottom: $spacing-5;
  }

  &_faqList {
    padding-left: $spacing-5;
    display: flex;
    flex-direction: column;
    gap: $spacing-7;
  }

  &_faqItem:not(:last-child) {
    position: relative;

    &:after {
      position: absolute;
      bottom: -$spacing-5;
      left: -$spacing-5;
      width: calc(100% + $spacing-5);
      height: 2px;
      content: '';
      background: $color-gray;
      border-radius: $border-radius-base;
    }
  }

  &_faqQuestion {
    font-size: $font-size-l;
    font-weight: 500;
    margin-bottom: $spacing-3;
  }

  &_faqAnswer {
    line-height: $font-size-xxl;
  }

  &_text {
    margin-top: $spacing-6;

    h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
      margin-top: $spacing-4;
      margin-bottom: $spacing-3;
    }

    a {
      color: $color-green-main;
      text-decoration: underline;
    }

    ul {
      margin-left: $spacing-5;
      margin-bottom: $spacing-4;
    }

    li:not(:last-child) {
      margin-bottom: $spacing-3;
    }
  }
}

@include for-screen-from-1024 {
  .listingsSeo {
    &__isMapView {
      display: block;
    }
  }
}
