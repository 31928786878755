@import "@/styles/variables";
@import "@/styles/mixins.scss";

.listingsListTitle {
  font-size: $font-size-l;
  font-weight: 500;
}

@include for-screen-from-768 {
  .listingsListTitle {
    font-size: $font-size-xxl;
  }
}
