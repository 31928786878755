@import "@/styles/variables";
@import "@/styles/mixins";

.embla {
  --slide-height: auto;
  --slide-size: 100%;
  --slide-spacing: 4px;

  position: relative;
  margin: auto 0;

  &_isNew {
    position: absolute;
    top: 16px;
    right: 0;
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    padding: $spacing-3 $spacing-5;
    z-index: 1;
    background-color: $color-green;
    color: #fff;
    font-size: $font-size-xs;
    text-transform: uppercase;
  }

  &_relativeDate {
    position: absolute;
    bottom: 16px;
    right: 0;
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    padding: $spacing-1 $spacing-3;
    z-index: 1;
    font-size: $font-size-xs;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__buttons {
  display: none;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  height: var(--slide-height);
  width: 100%;
  display: flex;
  //justify-content: center;
  //align-items: center;
}

.embla__slide__img {
  display: block;
  //height: var(--slide-height);
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: auto;
}

.embla__slide__img {
  user-select: none;
}

.listingDetailAllPhotos {
  position: fixed;
  z-index: $z-index-listing-detail-all-photos;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //height: 100%;
  //width: 100%;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;
  overflow-x: hidden;
  overflow-y: scroll;

  &__isPhotosCarouselShow {
    background-color: #000;
    overflow: hidden;

    .listingDetailAllPhotos_wrapper {
      overflow: hidden;
      width: 100%;
      padding: 0 $spacing-3;
      display: flex;
      flex-direction: column;
    }

    .listingDetailAllPhotos_backIcon {
      color: $color-gray;

      &:hover {
        color: #fff;
      }
    }
  }

  &_bookmark {
    scale: 1;
    transition:
            scale 0.1s ease-in-out;
    animation: normal;
    font-size: 26px;
    line-height: 0;
    padding: 7px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      scale: 1.15;
    }
  }

  &_bookmarkIcon {
    stroke-width: 18;
    stroke: #fff;
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.2s ease-in-out;

    &__isBookmarked {
      color: $color-green-main;
      stroke: $color-green-main;
    }
  }
}

.listingDetailAllPhotos_wrapper {
  position: relative;
  height: 100dvh;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 0 $spacing-3;
}

.listingDetailAllPhotos_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing-4;
}

.listingDetailAllPhotos_backIcon {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 38px;
  align-items: center;
  font-size: 25px;
  color: $color-gray-dark;
  cursor: pointer;
  line-height: 0;
  border: none;
  background-color: unset;

  svg {
    transition:
            transform 0.2s ease-in-out,
            background-color 0.2s ease-in-out;
  }

  &:hover {
    color: $color-black;

    svg {
      transform: scale(1.15);
    }
  }
}

.listingDetailAllPhotos_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 420px 245px;
  gap: $spacing-3;
  padding-bottom: $spacing-7;
}

.listingDetailAllPhotos_item {
  position: relative;
  border: none;

  img {
    object-fit: cover;
  }
}

.listingDetailAllPhotos_item:nth-child(3n + 1) {
  grid-column:span 2;
}

.listingDetailAllPhotos_image {
  display: block;
  width: 100%;
  height: auto;
}

.listingDetailAllPhotos_margin {
  padding-bottom: $spacing-7;
}



@include for-screen-from-768 {
  .embla {
    position: relative;
  }

  .embla__buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  .listingDetailAllPhotos {
    &__isPhotosCarouselShow {
      .listingDetailAllPhotos_wrapper {
        padding: 0 $spacing-8;
      }
    }
  }
}

@include for-screen-from-1024 {
  .embla {
    max-width: 100%;
    position: relative;
    padding-top: $spacing-5;
    padding-bottom: $spacing-7;
    margin-left: $spacing-8;
    margin-right: $spacing-8;
    --slide-size: 100%;
  }
}
