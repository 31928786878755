@import "@/styles/variables";

.listingsSeoLinks {
  margin-top: $spacing-7;

  &_wrapper {
    display: flex;
    gap: $spacing-5;
  }

  &_container {
    flex: 1;
    position: relative;

    &:not(:first-child):before {
      display: block;
      position: absolute;
      top: 18px;
      bottom: 0;
      left: -8px;
      width: 1px;
      border-radius: $border-radius-base;
      content: '';
      background-color: $color-gray-dark;
    }
  }

  &_title {
    font-weight: 500;
  }

  &_areaTitle {
    font-weight: 500;
    margin: $spacing-4 0;
    color: $color-gray-dark;
  }

  &_list {
    padding: 0;
    column-width: 160px;
    list-style: none;
  }

  &_item {
    margin-bottom: $spacing-4;
    color: $color-green-main;

    &:hover {
      text-decoration: underline;
    }
  }
}
