@import '@/styles/variables.scss';
@import "@/styles/mixins.scss";

.listingsSection {
  z-index: 1;
  background-color: #fff;
}

.listings {
  margin-top: $spacing-4;

  &_top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-3;
    margin-top: $spacing-1;
  }

  &_addListing {
    max-width: 160px;
    width: 100%;
  }

  &_addListing__desktop {
    display: none;
  }
}

@include for-screen-from-768 {
  .listings_top {
    margin: $spacing-3 0 $spacing-5 0;
  }
}

@include for-screen-from-1024 {
  .listingsSection__isMapView {
    display: block;
  }

  .listings {
    &_addListing__mobile {
      display: none;
    }

    &_addListing__desktop {
      display: block;
    }
  }
}
