@import "@/styles/variables";
@import "@/styles/mixins.scss";

.listingsList {
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacing-4;

  &__isMapView {
    display: none;
  }

  &_listingCard {
    min-width: 290px;
  }
}

@include for-screen-from-768 {
  .listingsList {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__list {
      grid-template-columns: 1fr;
    }

    &_listingCard {
      min-width: 290px;
    }
  }
}

@include for-screen-from-1024 {
  .listingsList {
    &__isMapView {
      display: grid;
    }

    &_listingCard {
      min-width: 310px;
    }
  }
}

@include for-screen-from-1200 {
  .listingsList_listingCard {
    min-width: 372px;
  }
}
