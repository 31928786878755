@import "@/styles/variables";
@import "@/styles/mixins";

.listingDetailGallery {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "main";
  grid-template-rows: 300px;
  border-radius: $border-radius-base;
  overflow: hidden;

  img {
    object-fit: cover;
  }

  .listingDetailGallery__mainImage {
    position: relative;
    grid-area: main;
  }

  .listingDetailGallery__extra1 {
    position: relative;
    grid-area: extra1;
  }

  .listingDetailGallery__extra2 {
    position: relative;
    grid-area: extra2;
  }

  .listingDetailGallery__extra3 {
    position: relative;
    grid-area: extra3;
  }

  .listingDetailGallery__extra4 {
    position: relative;
    grid-area: extra4;
  }

  &_bookmark {
    position: absolute;
    z-index: 3;
    top: $spacing-4;
    left: $spacing-4;
    scale: 1;
    transition:
      scale 0.1s ease-in-out;
    animation: normal;
    font-size: 26px;
    line-height: 0;
    padding: 7px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      scale: 1.15;
    }
  }

  &_bookmarkIcon {
    stroke-width: 18;
    stroke: #fff;
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.2s ease-in-out;

    &__isBookmarked {
      color: #fff;
    }
  }

  &_isNew {
    position: absolute;
    top: $spacing-4;
    right: 0;
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    padding: $spacing-3 $spacing-5;
    z-index: 1;
    background-color: $color-green;
    color: #fff;
    font-size: $font-size-s;
    text-transform: uppercase;
  }

  &_relativeDate {
    position: absolute;
    bottom: $spacing-4;
    left: 0;
    border-bottom-right-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    padding: $spacing-2 $spacing-4;
    z-index: 1;
    font-size: $font-size-s;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &_showAllButton {
    position: absolute;
    z-index: 1;
    right: $spacing-4;
    bottom: $spacing-4;
    padding: $spacing-2 $spacing-5;
    border-radius: $border-radius-base;
    border: 1px solid $color-black;
    background: #fff;
    font-size: $font-size-s;
    color: $color-black;
    cursor: pointer;
  }
}

@include for-screen-from-768 {
  .listingDetailGallery {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: repeat(2, 215px);
    gap: $spacing-3;
  }

  .listingDetailGallery__five {
    grid-template-areas:
    "main extra1 extra2"
    "main extra3 extra4";
  }

  .listingDetailGallery__four {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "main extra1"
    "extra2 extra3";
  }

  .listingDetailGallery__three {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "main extra1 extra2"
    "main extra1 extra2";
  }

  .listingDetailGallery__two {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "main extra1"
    "main extra1";
  }

  .listingDetailGallery__one {
    grid-template-columns: 1fr;
    grid-template-areas:
    "main"
    "main";
  }
}
